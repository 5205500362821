
:root { 
	--blue-color: #253057;
	--white-color: #fff;
	--headline-color: #e8ff3d;
	--light-bg-color:  #5f658a;
	--small-font: 0.8rem;
	--mid-font: 1rem;
	--large-font:1.5rem;
	--very-large-font:2rem;

}
.App {
  height: 100vh;
}



.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #0f233a;
  color: #fff;
  border-top: 2px solid #e44a3c;
  flex-wrap: wrap;

}

.header_left_content{

}

.header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
}

.header_left>img {
  width: 100px;
}

.header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.header_right>h5 {
  margin-left: 16px;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.header_right>h5:hover {
  color: #e44a3c;
}



/******************Banner********************/



/**********About************/

.about_page {

  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  flex-direction: row;
  padding: 4rem;
}


.about_head{
  text-align: left;
}

.about_box{
  margin: 2rem;
}

.about_head>p{
  background-color: #294a70;
  text-align: left;
  border-top: 3px solid #e44a3c;
  padding: 10px;
  font-weight: bold;
  color: #fff;
}



.about_content>p{
  font-weight: bold;
  color: #333;
}
.alerts_rows>p{
  cursor: pointer;
  font-weight: 400;
  color: #294a70;
}

.alerts_rows>p>a{
  text-decoration: underline;
  color: #0000ff;
}

.viewAll{
  text-decoration: underline;
  color: #294a70;
}

.gallery{
  display: grid;
  grid-template-columns:repeat(auto-fit,minmax(250px,1fr));
}
.gallery_comp{
  margin: 5px;
  cursor: pointer;
}
.gallery_comp>img{
  width: 100%;
  margin-right: 10px;
}


/***********footer*************/
.footer{
  background-color: #0f233a;
}

.footer>p{
  text-align: center;
  padding: 5px;
  color: #fff;
}

.footer_grid{
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}

.footer_comp_head{
  background-color: #294a70;
  font-weight: bold;
  color: #fff;
}

.footer_comp{
  width: 90%;

}

.footer_comp_head>p{
  padding: 10px;
  border-top: 3px solid #e44a3c;
}
.footer_comp_content{
  
  color: #fff;
}

.footer_comp_content>p>mark{
  background-color: #0f233a;
  color: #fff;
  font-weight: bold;
}

.footer_copyright_line{
  background-color: #294a70;
}

.social_links{
  display: flex;
  flex-direction: row;
  color: #fff;
}

.social_links>p{
  margin-right: 10px;
  font-size: 1.5rem;
}

.fa{
  cursor: pointer;
}

/****************fac_grid ***********/

.fac_grid{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
}

.fac_border{
  border:2px solid #f8f8f8;
  padding: 2px;
  border-radius: 10px;
}

.fac_comp{
  margin: 2rem;
}

.fac_img>img{
  width: 100%;
  border-radius: 10px;
}
.fac_content{
  margin: 2rem;
}
.fac_content>p{
  font-weight: 500;
}


/************syllabus_rows*************/

.syllabus_comp{
  padding:5px;
  margin: 5px;
}

.syllabus_rows{
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border:1px solid #ccc;
  font-weight: 500;
}

.syllabus_download_link{
  color: #294a70;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}


.syllabus_head{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


/***************department_rows ************/

.department_rows{

}

.department_rows>img{
  width: 200px;
}

.department_content{
  display:grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}

.department_comp{
  padding: 10px;
}

.department_rows{
  word-break: break-all;
  padding:1rem;
  margin: 3px;
}

.timestamp{
  color: #333
}

.row_alerts{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/***************Grievance***************/
.form_main_grid{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
	grid-gap: 1rem;
	padding: 1rem;
	align-items: flex-start;
}
.form_comp{

}

.form_comp>h1{
	font-size: var(--mid-font);
	color: var(--blue-color);
}
.form_comp>h4{
	font-size: var(--small-font);
	color: #666;
	font-weight: 400;
}



.input_form{
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.input_form>label{
	font-size: var(--small-font);
}
.input_form>input{
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	border: 1px solid #ccc;
}

.input_form>textarea{
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	font-family: "Montserrat";
	border: 1px solid #ccc;
}



.blue_button{
	outline: 0;
	border: 0;
	padding: 0.8rem 1rem 0.8rem 1rem;
	background:var(--blue-color);
	color: #fff;
	font-size: var(--mid-font);
	align-self: flex-start;
	cursor: pointer;
}
.blue_button:hover{
	background: #ccc;
	color: var(--blue-color);
}


/****loading****/

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  font-size: 24px; /* You can adjust the font size */
}


/***admission*****/

.admission{
  padding: 1rem;
}

.admission_page{
  text-align: center;
}

.admission_img{
  width: 60%;
  height: auto;

}


/*****/

.stats{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-container {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-color);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
}

.stat-card {
  flex: 1;
  text-align: center;
  color: #ffffff;
}

.stat-value {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.stat-label {
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  color: #cce4ff;
}

.stat-card:not(:last-child) {
  border-right: 1px solid #cce4ff;
  padding-right: 20px;
}

.alumni_form{
  padding: 2rem;
}

.alumni_form>h1{
  font-size: 1rem;
}

.alumni_form>p{
  font-size: 1rem;
}